<template>
	<div class="layout-footer">
		<span class="font-medium ml-2">Versão {{version}}</span>
	</div>
</template>

<script>
	export default {
			
		name: "AppFooter",
		data() {
			return {
				// Major-Minor-Patch
				version: "2.1.1 - 2025"
			};
		},
		methods: {
			footerImage() {
				return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
			}
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			}
		}
	}
</script>